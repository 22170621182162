import { Box, Divider, FormControl, MenuItem, Typography } from "@mui/material";
import React, { useRef, useState } from "react";
import { CheckboxInput, Dropdown, InputBox } from "../../../../InputBox/InputBox";
import CustomAccordion from "../../../../Accrodion/Accordion";
import { Constants, creditPoints } from "../../../../../config/Constants";
import ButtonMain from "../../../../ButtonMain";
import { ToastNotify } from "../../../../SnackBar/ToastNotify";
import { useOutletContext } from "react-router-dom";
import { POST } from "../../../../../config/Api";
import { removeQueryParams, useQueryParams } from "../../../../../utils";

const AddSurveyMonkey = ({
  assessmentSettings,
  block,
  type,
  updateOrder,
  setblockModOpen,
  setmodalOpen,
  setCourseBlocks,
  blockModalOpen,
}) => {
  const [assessment, setAssessment] = useState("");
  const [disable, setDisable] = useState(false);
  const [inputValue, setInputValue] = useState({
    url: block?.url || "",
    position_label: block?.position_label || "",
    score: block?.typeOfBlock || "attempt",
    credit: block?.score || 5,
    checked: false,
    is_required: block?.is_required || false,
    assessmentType: block?.assessmentType || "",
  });
  const toastRef = useRef();
  const { setCourseOne } = useOutletContext();
  const { cid, mid } = useQueryParams();

  const handleSave = async () => {
    if (inputValue.url.trim() === "") {
      toastRef.current.showToastMessage("Please Enter url.", "error");
    } else if (inputValue.position_label.trim() === "") {
      toastRef.current.showToastMessage("Please Enter position label.", "error");
    } else {
      let payload = {
        course: cid,
        container: mid,
        order: blockModalOpen?.addBelow ? blockModalOpen?.currentBlockOrder + 1 : updateOrder || 1,
        response_type: "embed",
        highlightIfIncorrect: inputValue.checked,
        typeOfBlock: inputValue.score,
        score: inputValue.credit,
        assessmentType: assessment,
        type: type,
        is_required: inputValue.is_required,
        url: removeQueryParams(inputValue.url),
        position_label: inputValue.position_label,
      };
      setDisable(true);
      await POST(`${Constants.addBlock}/survey-monkey?_id=${block?._id}`, payload)
        .then((response) => {
          setDisable(false);
          toastRef.current.showToastMessage("Block Created Successfully.", "success");
          setCourseOne((prev) => ({ ...prev, published: false }));
          if (type === "edit") {
            setCourseBlocks &&
              setCourseBlocks((prev) => {
                const elIndex = prev.findIndex((el) => el._id === block?._id);
                const updatedBlocks = prev.map((el) => {
                  if (el.order >= response?.data?.order) {
                    el.order += 1;
                  }
                  return el;
                });
                updatedBlocks.splice(elIndex, 1, response?.data);
                updatedBlocks.sort((a, b) => a?.order - b?.order);
                return updatedBlocks;
              });
          } else {
            if (blockModalOpen?.addBelow) {
              setCourseBlocks &&
                setCourseBlocks((prev) => {
                  const updatedBlocks = prev.map((el) => {
                    if (el.order >= response?.data?.order) {
                      el.order += 1;
                    }
                    return el;
                  });
                  const elIndex = updatedBlocks.findIndex(
                    (el) => el._id === blockModalOpen?.currentBlockId,
                  );
                  updatedBlocks.splice(elIndex + 1, 0, response?.data);
                  return updatedBlocks;
                });
            } else {
              setCourseBlocks &&
                setCourseBlocks((prev) => {
                  prev.forEach((el) => {
                    el.order = el.order + 1;
                  });
                  return [response?.data, ...prev];
                });
            }
          }
          setmodalOpen && setmodalOpen((prev) => ({ ...prev, modalOpen: false }));
          setblockModOpen(false);
        })
        .catch((error) => console.log(error));
    }
  };
  return (
    <div>
      <ToastNotify ref={toastRef} duration={3000} />
      <FormControl
        disabled={!assessmentSettings?.isAdvanceAssessment}
        sx={{ paddingBottom: "10px", width: "100%" }}
        variant="standard"
      >
        <Dropdown
          onChange={(event) => setAssessment(event.target.value)}
          value={assessment}
          renderValue={(selected) => {
            if (selected?.length === 0) {
              return <span className="dropdownLabel">Select a Domain</span>;
            } else {
              let selectedDomain = assessmentSettings?.assessmentDomain?.find(
                (domain) => domain?._id === selected,
              );
              return <span>{selectedDomain?.title}</span>;
            }
          }}
        >
          {assessmentSettings?.assessmentDomain?.length > 0 &&
            assessmentSettings?.assessmentDomain?.map((item, i) => {
              return (
                <MenuItem value={item?._id} key={i}>
                  {item?.title}
                </MenuItem>
              );
            })}
        </Dropdown>
      </FormControl>
      <InputBox
        placeholder="Enter URL"
        name="url"
        value={inputValue.url}
        onChange={(e) => setInputValue({ ...inputValue, url: e.target.value })}
      />
      <Box mt={1}>
        <InputBox
          placeholder="Enter Position Label"
          name="position_label"
          value={inputValue.position_label}
          onChange={(e) => setInputValue({ ...inputValue, position_label: e.target.value })}
        />
      </Box>
      <Typography variant="body2" mt={1}>
        You can choose to include the following custom variables in your SurveyMonkey form settings.
        <br />
        "uniqueid", "courseid", "blockid", "position_labels", "cohortinviteCode"
      </Typography>
      <Divider sx={{ borderColor: "black", margin: "10px 0px" }} />
      <CustomAccordion
        first={
          <Dropdown
            onChange={(event) => setInputValue({ ...inputValue, score: event.target.value })}
            value={inputValue.score}
          >
            {["attempt", "none"]?.map((item, index) => (
              <MenuItem value={item} key={index}>
                {item}
              </MenuItem>
            ))}
          </Dropdown>
        }
        second={
          <Dropdown
            onChange={(event) => setInputValue({ ...inputValue, credit: event.target.value })}
            value={inputValue.credit}
          >
            {creditPoints?.map((item, index) => (
              <MenuItem value={item} key={index}>
                {item}
              </MenuItem>
            ))}
          </Dropdown>
        }
        third={
          <CheckboxInput
            label="Highlight if responded incorrectlyinfo"
            onChange={(event) => setInputValue({ ...inputValue, checked: event.target.checked })}
            value={!inputValue.checked}
          />
        }
        fourth={
          <CheckboxInput
            label="Mark As Required"
            onChange={(event) =>
              setInputValue({
                ...inputValue,
                is_required: event.target.checked,
              })
            }
            value={!inputValue.is_required}
          />
        }
      />
      <ButtonMain className="buttonGreen" onClick={handleSave} disabled={disable}>
        Save Block
      </ButtonMain>
    </div>
  );
};

export default AddSurveyMonkey;
