import { createContext, useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";

import Course from "./pages/courses/Course";
import Home from "./pages/home/home";
import Support from "./pages/support/support";
import ResetPassword from "./pages/resetPassword/resetPassword";
import { ScrollToTop } from "./components/ScrollToTop/ScrollToTop";
import BackToTop from "./components/ScrollToTop/BackToTop";

import "./App.scss";

import ManagedCourses from "./pages/managed-courses/managed-courses";
import CohortManagement from "./components/CohortManagement";
import CohortView from "./components/courses/CohortView/CohortView";
import CreateBlock from "./components/courses/CreateBlock/CreateBlock";
import CreateBlockContainer from "./components/courses/CreateBlockContainer/CreateBlockContainer";
import ProfilePage from "./pages/profile/ProfilePage";
import VerifyCertificate from "./components/courses/VerifyCertificate/VerifyCertificate";
import CertificateConfig from "./pages/certificate-config";
import NotFound from "./components/NotFound/NotFound";
import CertificateListing from "./pages/CertficateListing";
import PaymentsReceipt from "./components/EnrolledCourses/PaymentReceipt/PaymentsReceipt";
import DiscussionForumLayout from "./pages/discussion-forum";
import CourseViewV2 from "./components/courses/CourseViewV2";
import CourseContainerV2 from "./components/courses/CourseContainerV2";
import DiscussionView from "./components/Discussion/DiscussionView/DiscussionView";
import PaymentDashboard from "./components/courses/PaymentDashboard/PaymentDashboard";
import FSComms from "./components/FSComms/FSComms";
import ScoreCardLayout from "./pages/scoreCard/scoreCardLayout";
import CourseLayout from "./components/courses/CourseLayout/CourseLayout";
import LearnerView from "./pages/LearnerView/LearnerView";
import LearnerList from "./pages/LearnerList/LearnerList";
import AuthModals from "./components/AllModals/AuthModals";
import ExportCourseContent from "./components/courses/ExportCourseContent/ExportCourseContent";
import { useEffect } from "react";
import { getCookie } from "./utils";
import { userLoginAction } from "./redux/action";
import { useDispatch, useSelector } from "react-redux";
import Admin from "./pages/admin";
// import SearchList from "./components/EnrolledCourseNew/LandingPage/SearchList";
// import Credits from "./pages/Credits/Credits";

const preferredLanguage = localStorage.getItem("preferredLanguage");
const initialStates = {
  loginOpen: false,
  signupOpen: false,
  forgotOpen: false,
  modalOpen: false,
  message: "",
  forceUpdate: false,
  preferredLanguage: preferredLanguage && JSON.parse(preferredLanguage),
};
export const AuthContext = createContext(initialStates);

function App() {
  const [authStates, setAuthStates] = useState(initialStates);
  let { isLoggedIn } = useSelector((state) => state.userLogin?.data) || {};
  const dispatch = useDispatch();
  useEffect(() => {
    let cookieName =
      process.env.REACT_APP_SERVER_TYPE === "prod" ? "fsUserData_PROD" : "fsUserData";
    let userData = getCookie(cookieName);
    if (userData && !isLoggedIn) {
      dispatch(userLoginAction(JSON.parse(userData)));
    }
  }, []);
  const themeLight = createTheme({
    typography: {
      fontFamily: "Figtree, sans-serif",
    },
    components: {
      MuiCssBaseline: {
        styleOverrides: `
          body, html, #root {
            font-family: 'Figtree, sans-serif';
          }
        `,
      },
    },
    palette: {
      background: {
        default: "#ffffff",
      },
      text: {
        primary: "#232323",
      },
    },
  });

  // const themeDark = createTheme({
  //   palette: {
  //     background: {
  //       default: "#1A1A1A",
  //     },
  //     text: {
  //       primary: "#ffffff",
  //     },
  //   },
  // });
  return (
    <ThemeProvider theme={themeLight}>
      <CssBaseline />
      <AuthContext.Provider value={{ authStates, setAuthStates }}>
        <BrowserRouter>
          <ScrollToTop>
            <BackToTop />
            <AuthModals />
            <Routes>
              {/* PRE-LOGIN */}
              <Route path="/" element={<Home />} />
              <Route path="/support" element={<Support />} />
              {/* <Route path="/credits" element={<Credits />} /> */}
              <Route path="/featured-courses" element={<Course />} />
              {/* <Route path="/search" element={<SearchList />} /> */}
              <Route path="/reset/:token" element={<ResetPassword />} />

              {/* POST-LOGIN */}
              {/* <Route path="/enrolled" element={<Enrolled />} />
              <Route path="/enrolled-courses" element={<EnrolledNew />} /> */}
              <Route path="/managed-courses" element={<ManagedCourses />} />
              <Route
                path="/managed-courses/:slug/cohortManagement"
                element={<CohortManagement />}
              />
              <Route path="course/payment-dashboard/:courseID" element={<PaymentDashboard />} />
              <Route path="course/export-content/:courseID" element={<ExportCourseContent />} />
              {/* Tabbed Layout Routes */}
              <Route path="/course" element={<CourseLayout />}>
                <Route path="/course/learner-view" element={<LearnerView />} />
                <Route path="/course/createblock" element={<CreateBlock />} />
                <Route
                  path="/course/createblock/createcontainer"
                  element={<CreateBlockContainer />}
                />

                <Route path="/course/:slug" element={<CourseViewV2 />} />
                <Route path="/course/:slug/discussion-forum" element={<DiscussionForumLayout />} />
                <Route path="/course/:slug/learner-dashboard" element={<ScoreCardLayout />} />
                <Route path="/course/:slug/:moduleSlug" element={<CourseContainerV2 />} />
              </Route>
              {/* Admin Routes */}
              <Route path="/admin/smform" element={<Admin />} />

              <Route path="/review" element={<LearnerList />} />
              <Route path="/cohort/:course_slug/:cohort_slug" element={<CohortView />} />
              {/* PRIVATE COURSE */}

              {/* USER PROFILE / SETTINGS */}
              <Route path="/user-profile" element={<ProfilePage />} />
              {/* VERIFIED USER SETTING */}
              <Route path="/certificate-config" element={<CertificateConfig />} />
              <Route path="/certificate-config/all" element={<CertificateListing />} />
              {/* VERIFY CERTIFICATE */}
              <Route path="/verifyCertificate" element={<VerifyCertificate />} />
              {/* To Get The Receipt */}
              <Route path="/receipt/:paymentID" element={<PaymentsReceipt />} />
              {/* Discussion forum */}
              <Route path="/create-discussion-forum" element={<DiscussionForumLayout />} />
              <Route path="/discussion-forum/:topicId" element={<DiscussionView />} />

              <Route path="/manage-fsComms" element={<FSComms />} />
              {/* If no route matched */}
              <Route path="/score" element={<ScoreCardLayout />} />
              <Route path="*" element={<NotFound />} />
            </Routes>
          </ScrollToTop>
        </BrowserRouter>
      </AuthContext.Provider>
    </ThemeProvider>
  );
}

export default App;
