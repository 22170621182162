import React, { useState } from "react";
import MUIDataTable from "mui-datatables";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import moment from "moment";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import GroupAddOutlinedIcon from "@mui/icons-material/GroupAddOutlined";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import QueryStatsOutlinedIcon from "@mui/icons-material/QueryStatsOutlined";
import HowToRegOutlinedIcon from "@mui/icons-material/HowToRegOutlined";
import MessageOutlinedIcon from "@mui/icons-material/MessageOutlined";
import { Typography } from "@mui/material";
import {
  Chip,
  Grid,
  Tooltip,
  Zoom,
  Drawer,
  List,
  ListItem,
  ListItemText,
  useMediaQuery,
  IconButton,
} from "@mui/material";
import { Box } from "@mui/system";
import "./CohortDataTable.scss";
import { useQueryParams } from "../../../utils";
import ListIcon from "@mui/icons-material/List";

const CohortDataTable = ({
  cohortList,
  handleEditCohort,
  handleAddMembers,
  handleLearners,
  handleInsightModal,
  handleCohortPreviewModal,
}) => {
  const { cid } = useQueryParams();
  const [joiningCodeCopy, setJoiningCodeCopy] = useState(false);
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState(null);

  const handleAddCohortData = (rowData) => {
    handleEditCohort(...rowData);
    setDrawerOpen(false);
  };

  const handleAddMembersData = (rowData) => {
    handleAddMembers(...rowData);
    setDrawerOpen(false);
  };

  const handlePreviewCohort = (rowData) => {
    handleCohortPreviewModal(...rowData);
    setDrawerOpen(false);
  };

  const handleLearnersData = (rowData) => {
    handleLearners(...rowData);
    setDrawerOpen(false);
  };

  const handleNavigate = (rowData) => {
    window.open(`/create-discussion-forum?cid=${cid}&coid=${rowData[0]}`, "_blank");
  };

  const handleCopyJoiningCode = (rowData) => {
    const joiningCode = rowData[10];
    navigator.clipboard.writeText(joiningCode);
    setJoiningCodeCopy(true);
    setTimeout(() => {
      setJoiningCodeCopy(false);
    }, 500);
    setDrawerOpen(false);
  };

  const getMuiTheme = () =>
    createTheme({
      components: {
        MuiTableHead: {
          styleOverrides: {
            root: {
              fontSize: "15px",
              textAlign: "center",
            },
          },
        },
        MUIDataTableBodyCell: {
          styleOverrides: {
            root: {
              textAlign: "center",
              fontSize: "15px",
            },
          },
        },
      },
    });

  const columns = [
    {
      name: "_id",
      options: {
        display: false,
      },
    },
    isMobile
      ? {
          name: "Title",
          options: {
            customHeadRender: (columnMeta, updateDirection) => (
              <th key={1} onClick={() => updateDirection(1)} className="table_head">
                {columnMeta.name}
              </th>
            ),
            customBodyRender: (value, tableMeta) => (
              <Box>
                <Typography>{value}</Typography>
                <Typography sx={{ mt: 2 }}>
                  {`${moment(tableMeta.rowData[2]).format("MMM DD, YYYY")} to ${moment(
                    tableMeta.rowData[3],
                  ).format("MMM DD, YYYY")}`}
                </Typography>
                <Box display={"flex"} justifyContent="center" className="" sx={{ mt: 2 }}>
                  {tableMeta.rowData[16] ? (
                    <Chip
                      label="Paid"
                      size="small"
                      className="moderator_badge"
                      color="info"
                      variant="contained"
                    />
                  ) : (
                    <Chip
                      label="Not Paid"
                      size="small"
                      className="facilitator_badge"
                      color="info"
                      variant="contained"
                    />
                  )}
                </Box>
              </Box>
            ),
          },
        }
      : {
          name: "Title",
          options: {
            customHeadRender: (columnMeta, updateDirection) => (
              <th key={1} onClick={() => updateDirection(1)} className="table_head">
                {columnMeta.name}
              </th>
            ),
          },
        },
    {
      name: "Start Date",
      options: {
        display: !isMobile,
        customHeadRender: (columnMeta, updateDirection) => (
          <th key={2} onClick={() => updateDirection(2)} className="table_head">
            {columnMeta.name}
          </th>
        ),
      },
    },
    {
      name: "End Date",
      options: {
        display: !isMobile,
        customHeadRender: (columnMeta, updateDirection) => (
          <th key={3} onClick={() => updateDirection(3)} className="table_head">
            {columnMeta.name}
          </th>
        ),
      },
    },
    {
      name: "learnerLimit",
      options: {
        display: false,
      },
    },
    {
      name: "customText",
      options: {
        display: false,
      },
    },
    {
      name: "Automatic Approval",
      options: {
        display: !isMobile,
        customHeadRender: (columnMeta, updateDirection) => (
          <th key={6} onClick={() => updateDirection(6)} className="table_head">
            {columnMeta.name}
          </th>
        ),
        customBodyRender: (value) => (
          <Box display={"flex"} justifyContent="center" className="">
            {value ? (
              <Chip
                label="Yes"
                size="small"
                className="moderator_badge"
                color="info"
                variant="contained"
              />
            ) : (
              <Chip
                label="No"
                size="small"
                className="facilitator_badge"
                color="info"
                variant="contained"
              />
            )}
          </Box>
        ),
      },
    },
    {
      name: "user_id",
      options: {
        display: false,
      },
    },
    {
      name: "courseId",
      options: {
        display: false,
      },
    },
    {
      name: "joiningLink",
      options: {
        display: false,
      },
    },
    {
      name: "joiningCode",
      options: {
        display: false,
      },
    },
    {
      name: "certificateSuffix",
      options: {
        display: false,
      },
    },
    {
      name: "certificateConfig",
      options: {
        display: false,
      },
    },
    {
      name: "signatoryConfig",
      options: {
        display: false,
      },
    },
    {
      name: "useCourseCertificateConfig",
      options: {
        display: false,
      },
    },
    {
      name: "useCourseSignatoryConfig",
      options: {
        display: false,
      },
    },
    {
      name: "is Paid",
      options: {
        display: !isMobile,
        customHeadRender: (columnMeta, updateDirection) => (
          <th key={16} onClick={() => updateDirection(16)} className="table_head">
            {columnMeta.name}
          </th>
        ),
        customBodyRender: (value) => (
          <Box display={"flex"} justifyContent="center" className="">
            {value ? (
              <Chip
                label="Yes"
                size="small"
                className="moderator_badge"
                color="info"
                variant="contained"
              />
            ) : (
              <Chip
                label="No"
                size="small"
                className="facilitator_badge"
                color="info"
                variant="contained"
              />
            )}
          </Box>
        ),
      },
    },
    {
      name: "type",
      options: {
        display: false,
      },
    },
    {
      name: "Actions",
      options: {
        customHeadRender: (columnMeta, updateDirection) => (
          <th key={11} onClick={() => updateDirection(11)} className="table_head_actions">
            {columnMeta.name}
          </th>
        ),
        customBodyRender: (value, tableMeta) => {
          const actionIcons = (
            <Grid container gap={2} alignItems="center" justifyContent={"flex-end"}>
              <Tooltip TransitionComponent={Zoom} title="Edit cohort basics">
                <IconButton
                  onClick={() => {
                    handleAddCohortData(tableMeta.rowData);
                  }}
                  color="default"
                >
                  <ModeEditOutlineOutlinedIcon />
                </IconButton>
              </Tooltip>
              <Tooltip TransitionComponent={Zoom} title="Add facilitator">
                <IconButton
                  onClick={() => {
                    handleAddMembersData(tableMeta.rowData);
                  }}
                  color="default"
                >
                  <GroupAddOutlinedIcon />
                </IconButton>
              </Tooltip>
              <Tooltip TransitionComponent={Zoom} title="Add Discussion">
                <IconButton
                  onClick={() => {
                    handleNavigate(tableMeta.rowData);
                  }}
                  color="default"
                >
                  <MessageOutlinedIcon />
                </IconButton>
              </Tooltip>
              <Tooltip
                TransitionComponent={Zoom}
                title={`${joiningCodeCopy ? "Invite Code Copied" : "Copy invite Code"}`}
              >
                <IconButton
                  color="default"
                  onClick={() => {
                    handleCopyJoiningCode(tableMeta.rowData);
                  }}
                >
                  <ContentCopyOutlinedIcon />
                </IconButton>
              </Tooltip>
              <Tooltip TransitionComponent={Zoom} title="Preview cohort snapshot">
                <IconButton
                  color="default"
                  onClick={() => {
                    handlePreviewCohort(tableMeta.rowData);
                  }}
                >
                  <VisibilityOutlinedIcon />
                </IconButton>
              </Tooltip>
              <Tooltip TransitionComponent={Zoom} title="Approve learner request">
                <IconButton
                  color="default"
                  onClick={() => {
                    handleLearnersData(tableMeta.rowData);
                  }}
                >
                  <HowToRegOutlinedIcon />
                </IconButton>
              </Tooltip>
              <Tooltip TransitionComponent={Zoom} title="Cohort Insights">
                <IconButton
                  color="default"
                  onClick={() => {
                    handleInsightModal(...tableMeta.rowData);
                  }}
                >
                  <QueryStatsOutlinedIcon />
                </IconButton>
              </Tooltip>
            </Grid>
          );

          return (
            <>
              {isMobile ? (
                <IconButton
                  onClick={(e) => {
                    setSelectedRowData(tableMeta.rowData);
                    setDrawerOpen(true);
                  }}
                  color="default"
                >
                  <ListIcon sx={{ fontSize: "2rem" }} />
                </IconButton>
              ) : (
                <Grid container>{actionIcons}</Grid>
              )}
            </>
          );
        },
      },
    },
  ];

  const options = {
    selectableRows: false,
    responsive: "standard",
    download: false,
    print: false,
    filter: false,
    viewColumns: false,
    pagination: true,
    rowsPerPage: 10,
    rowsPerPageOptions: [10, 20, 50],
  };

  return (
    <>
      <Box mt={5}>
        <ThemeProvider theme={getMuiTheme()}>
          <MUIDataTable
            data={cohortList.map((cohort) => [
              cohort._id,
              `${cohort?.title}${cohort.type === "default" ? " - self directed" : ""}`,
              moment(cohort.startDate).format("MMM DD, YYYY"),
              moment(cohort.endDate).format("MMM DD, YYYY"),
              cohort.learnerLimit,
              cohort.customText,
              cohort.automaticApproval,
              cohort.user_id,
              cohort.courseId,
              cohort.joiningLink,
              cohort.joinCode,
              cohort.certificateSuffix,
              cohort.certificateConfig,
              cohort.signatoryConfig,
              cohort.useCourseCertificateConfig,
              cohort.useCourseSignatoryConfig,
              cohort.is_Paid,
              cohort.type,
            ])}
            columns={columns}
            options={options}
          />
        </ThemeProvider>
      </Box>
      <Drawer
        anchor="bottom"
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
      >
        <List>
          {selectedRowData && (
            <>
              <ListItem button onClick={() => handleAddCohortData(selectedRowData)}>
                <ModeEditOutlineOutlinedIcon sx={{ mr: 2 }} />
                <ListItemText primary="Edit cohort basics" />
              </ListItem>
              <ListItem button onClick={() => handleAddMembersData(selectedRowData)}>
                <GroupAddOutlinedIcon sx={{ mr: 2 }} />
                <ListItemText primary="Add facilitator" />
              </ListItem>
              <ListItem button onClick={() => handleNavigate(selectedRowData)}>
                <MessageOutlinedIcon sx={{ mr: 2 }} />
                <ListItemText primary="Add Discussion" />
              </ListItem>
              <ListItem button onClick={() => handleCopyJoiningCode(selectedRowData)}>
                <ContentCopyOutlinedIcon sx={{ mr: 2 }} />
                <ListItemText
                  primary={`${joiningCodeCopy ? "Invite Code Copied" : "Copy invite Code"}`}
                />
              </ListItem>
              <ListItem button onClick={() => handlePreviewCohort(selectedRowData)}>
                <VisibilityOutlinedIcon sx={{ mr: 2 }} />
                <ListItemText primary="Preview cohort snapshot" />
              </ListItem>
              <ListItem button onClick={() => handleLearnersData(selectedRowData)}>
                <HowToRegOutlinedIcon sx={{ mr: 2 }} />
                <ListItemText primary="Approve learner request" />
              </ListItem>
              <ListItem button onClick={() => handleInsightModal(...selectedRowData)}>
                <QueryStatsOutlinedIcon sx={{ mr: 2 }} />
                <ListItemText primary="Cohort Insights" />
              </ListItem>
            </>
          )}
        </List>
      </Drawer>
    </>
  );
};

export default CohortDataTable;
