import React from "react";
import HeaderContainer from "../../components/HeaderContainer/HeaderContainer";
import AdminLayout from "../../components/Admin";

const Admin = () => {
  return (
    <HeaderContainer>
      <AdminLayout />
    </HeaderContainer>
  );
};

export default Admin;
