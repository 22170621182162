import React, { Fragment } from "react";
import SMFormCard from "../Cards/SMFormCard";
import { Typography } from "@mui/material";

const ActiveForms = ({
  activeForms,
  setEditModal,
  handleChangeStatusOfForm,
  handleDelete,
  setOpen,
}) => {
  return (
    <div>
      {activeForms.length > 0 ? (
        <>
          {activeForms.map((item) => (
            <Fragment key={item?.survey_id}>
              <SMFormCard
                form={item}
                setEditModal={setEditModal}
                handleChangeStatusOfForm={handleChangeStatusOfForm}
                handleDelete={handleDelete}
                setOpen={setOpen}
              />
            </Fragment>
          ))}{" "}
        </>
      ) : (
        <Typography variant="h6" m={2}>
          Nothing here ...
        </Typography>
      )}
    </div>
  );
};

export default React.memo(ActiveForms);
