import React from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import ArtView from "../ArtView/ArtView";
import BlockActions from "../BlockActions/BlockActions";
import "./TextView.scss";
import LocalOfferRoundedIcon from "@mui/icons-material/LocalOfferRounded";
import { Tooltip } from "@mui/material";
import { styled } from "@mui/material/styles";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

const Image = styled("span")(({ theme }) => ({
  position: "absolute",
  left: 0,
  right: 0,
  bottom: 0,
  display: "flex",
  alignItems: "right",
  justifyContent: "right",
  color: theme.palette.common.white,
}));

const MenuIconWrapper = styled("div")(({ theme }) => ({
  position: "absolute",
  top: 10,
  right: 10,
  display: "none",
  [theme.breakpoints.down("sm")]: {
    display: "block",
  },
}));

const TextViews = ({
  block,
  theme,
  art,
  courseDetails,
  courseDetail,
  courseOne,
  setmodalOpen,
  setblockModOpen,
}) => {
  const themes = useTheme();
  const isMobile = useMediaQuery(themes.breakpoints.down("sm")); // Check for mobile view
  let { isLoggedIn, user } = useSelector((state) => state.userLogin?.data) || {};

  let isModerator =
    courseDetails?.creator === user?.id ||
    courseDetails?.members?.findIndex((item) => item?.user === user?.id);

  const [isHovering, setIsHovering] = useState(false);
  const [hidden, setHidden] = useState(block?.is_hidden);
  const [showActions, setShowActions] = useState(false);

  const handleHover = () => {
    setIsHovering(true);
  };
  const handleMouseLeave = () => {
    setIsHovering(false);
  };

  const handleMenuClick = (e) => {
    e.stopPropagation();
    setShowActions(!showActions);
  };

  return (
    <>
      {(isModerator === -1 || isModerator === undefined) && block.is_hidden ? (
        ""
      ) : (
        <div
          id="text-view"
          className={` 
      
     ${block?.theme ? block?.theme : "postBgColor"}
   `}
          style={{
            background: `${block?.theme_type === "color" ? block?.color : ""}`,
          }}
          onMouseOver={handleHover}
          onMouseLeave={handleMouseLeave}
        >
          {isModerator !== -1 && isLoggedIn && (isHovering || showActions) && (
            <BlockActions
              block={block}
              courseDetails={courseDetails}
              setmodalOpen={setmodalOpen}
              setblockModOpen={setblockModOpen}
              hidden={hidden}
              setHidden={setHidden}
            />
          )}

          <div
            className={`text_view_content ${art?.m ? "text_with_art" : ""}`}
            dangerouslySetInnerHTML={{ __html: block?.text }}
          ></div>
          {art?.m && <ArtView art={art} />}

          {isModerator !== -1 && block?.carousel_container && (
            <Image>
              <Tooltip title="This Block Tagged to Carousel Block">
                <LocalOfferRoundedIcon fontSize="large" color="primary" />
              </Tooltip>
            </Image>
          )}

          {isMobile && (
            <MenuIconWrapper onClick={handleMenuClick}>
              <MoreVertIcon fontSize="large" color="action" />
            </MenuIconWrapper>
          )}
        </div>
      )}
    </>
  );
};

export default TextViews;
